const itfFairParticipationManagement = '/itf-trade-fair/fair-parti-payment-info/'
export const itfFairPariPaymentInfo = itfFairParticipationManagement + 'list'
export const itfFairPariPaymentInfoReject = itfFairParticipationManagement + 'reject'
export const itfFairPariPaymentInfoApprove = itfFairParticipationManagement + 'approve'
export const itfFairPariPaymentInfoReturn = itfFairParticipationManagement + 'return'
export const itfFairPariPaymentInfoCriteria = itfFairParticipationManagement + 'criteriaList'
export const itfFairPariPaymentInfoEvaluate = itfFairParticipationManagement + 'evaluate'

// Approved Participant Routes......
const approvedParticipant = '/itf-trade-fair/approved-participant/'
export const approvedParticipantList = approvedParticipant + 'list'

// Approved Participant Routes......
const rejectedParticipant = '/itf-trade-fair/rejected-participant/'
export const rejectedParticipantList = rejectedParticipant + 'list'
export const fairPartListDetailsApi = '/itf-trade-fair/fair-participation/details'
export const fairInfoByCircularApi = '/itf-trade-fair/fair-participation/fair-info'
export const fairPartPdfDetailsApi = '/itf-trade-fair/fair-participation/pdf-details'
export const fairConsentPaperPdfApi = '/itf-trade-fair/fair-participation/pdf-consent'

// Visa Processing Api...
export const visaDocsStoreApi = '/itf-trade-fair/visa-processing/store'
export const visaProcessingApproveApi = '/itf-trade-fair/visa-processing/approve'
export const visaProcessingRefundApi = '/itf-trade-fair/visa-processing/refund'

// Fair Attendance Api......
const fairAttendanceInfo = '/itf-trade-fair/fair-attendance-info/'
export const fairAttendanceInfoList = fairAttendanceInfo + 'list'
export const getParticipantInfo = fairAttendanceInfo + 'get-parti-info'
export const attendanceStore = fairAttendanceInfo + 'attendance-store'
export const fairPartAttendListApi = fairAttendanceInfo + 'attendance-list'
export const getStallCatByFairApi = fairAttendanceInfo + 'stall-cat-by-fair'
export const getAttendenceSortList = fairAttendanceInfo + 'attendence-sort'
// Fair Evaluation Api...
export const adminFairEvaluationListApi = '/itf-trade-fair/admin-fair-evaluation/list'
export const adminFairEvaluationApproveApi = '/itf-trade-fair/admin-fair-evaluation/approve'
export const adminFairEvaluationReturnApi = '/itf-trade-fair/admin-fair-evaluation/return'
